import React from 'react'

import {
  faWrench,
  faUmbrella,
  faTree,
  faTools,
  faThumbsUp,
  faThumbtack,
  faThermometerFull,
  faThermometerEmpty,
  faTape,
  faTasks,
  faTags,
  faTag,
  faSun,
  faStopwatch,
  faSpellCheck,
  faSmile,
  faSmileWink,
  faSignature,
  faSignal,
  faShieldAlt,
  faSeedling,
  faSearch,
  faSearchLocation,
  faSatellite,
  faSatelliteDish,
  faSave,
  faRuler,
  faRulerVertical,
  faRocket,
  faRoad,
  faReceipt,
  faPowerOff,
  faPhotoVideo,
  faPaw,
  faPaperPlane,
  faPaperclip,
  faPaintRoller,
  faMouse,
  faMountain,
  faMoon,
  faMobileAlt,
  faMicrophoneAlt,
  faMicroscope,
  faMarker,
  faMailBulk,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faLungs,
  faMagnet,
  faKey,
  faKeyboard,
  faLaptop,
  faImages,
  faHourglassHalf,
  faHome,
  faHiking,
  faHeadphones,
  faHeadset,
  faGlobeAmericas,
  faGlobe,
  faGlasses,
  faGem,
  faFolderOpen,
  faFlask,
  faFeather,
  faFan,
  faEye,
  faEnvelope,
  faEnvelopeOpenText,
  faDove,
  faDna,
  faDesktop,
  faConciergeBell,
  faComment,
  faComments,
  faCloudShowersHeavy,
  faCloudSun,
  faClock,
  faCity,
  faChartPie,
  faCheck,
  faChartBar,
  faBatteryEmpty,
  faBatteryFull,
  faAtom,
  faAppleAlt,
  faAt,
  faLink
} from '@fortawesome/free-solid-svg-icons'

import {
  faTwitter,
  faYoutube,
  faFacebook,
  faLinkedin,
  faBlogger,
  faInstagram,
  faDiscord,
  faReddit,
  faGooglePlus
} from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const transIcons = {
  "Herramienta": faWrench,
  "Herramientas": faTools,
  "Paragua": faUmbrella,
  "Arbol": faTree,
  "Pulgar arriba": faThumbsUp,
  "Pin": faThumbtack,
  "Termometro lleno": faThermometerFull,
  "Termometro vacio": faThermometerEmpty,
  "Cinta": faTape,
  "Lista": faTasks,
  "Etiqueta": faTag,
  "Etiquetas": faTags,
  "Sol": faSun,
  "Cronometro": faStopwatch,
  "Spell check": faSpellCheck,
  "Sonrisa": faSmile,
  "Sonrisa guiño": faSmileWink,
  "Firma": faSignature,
  "Señal": faSignal,
  "Escudo": faShieldAlt,
  "Planta": faSeedling,
  "Lupa": faSearch,
  "Lupa ubicacion": faSearchLocation,
  "Satelite": faSatellite,
  "Antena satelital": faSatelliteDish,
  "Diskette": faSave,
  "Regla": faRuler,
  "Regla vertical": faRulerVertical,
  "Cohete": faRocket,
  "Ruta": faRoad,
  "Recibo": faReceipt,
  "Power off": faPowerOff,
  "Foto video": faPhotoVideo,
  "Garra": faPaw,
  "Avion": faPaperPlane,
  "Clip": faPaperclip,
  "Rodillo": faPaintRoller,
  "Mouse": faMouse,
  "Montaña": faMountain,
  "Luna": faMoon,
  "Celular": faMobileAlt,
  "Microfono": faMicrophoneAlt,
  "Microscopio": faMicroscope,
  "Lapicera": faMarker,
  "Cartas": faMailBulk,
  "Mapa": faMap,
  "Mapa ubicacion": faMapMarkedAlt,
  "Ubicacion": faMapMarkerAlt,
  "Pulmones": faLungs,
  "Iman": faMagnet,
  "Llave": faKey,
  "Teclado": faKeyboard,
  "Notebook": faLaptop,
  "Imagenes": faImages,
  "Reloj arena": faHourglassHalf,
  "Hogar": faHome,
  "Hiking": faHiking,
  "Auriculares": faHeadphones,
  "Auriculares microfono": faHeadset,
  "Mundo": faGlobeAmericas,
  "Mundo internet": faGlobe,
  "Anteojos": faGlasses,
  "Diamante": faGem,
  "Carpeta": faFolderOpen,
  "Matraz": faFlask,
  "Pluma": faFeather,
  "Ventilador": faFan,
  "Ojo": faEye,
  "Sobre": faEnvelope,
  "Sobre abierto": faEnvelopeOpenText,
  "Paloma": faDove,
  "ADN": faDna,
  "Monitor": faDesktop,
  "Campana hotel": faConciergeBell,
  "Comentario": faComment,
  "Comentarios": faComments,
  "Lluvia": faCloudShowersHeavy,
  "Nubes sol": faCloudSun,
  "Reloj": faClock,
  "Ciudad": faCity,
  "Grafico torta": faChartPie,
  "Grafico barras": faChartBar,
  "Tick": faCheck,
  "Bateria vacia": faBatteryEmpty,
  "Bateria llena": faBatteryFull,
  "Atomo": faAtom,
  "Manzana": faAppleAlt,
  "Arroba": faAt
}

const transSocialMedia = {
  "Twitter": faTwitter,
  "YouTube": faYoutube,
  "Facebook": faFacebook,
  "Linkedin": faLinkedin,
  "Blogger": faBlogger,
  "Instagram": faInstagram,
  "Discord": faDiscord,
  "Reddit": faReddit,
  "Google+": faGooglePlus,
  "Mail": faEnvelope,
  "default": faLink
}

export default ({
  name,
  size='6x',
  className,
  style
}) => <FontAwesomeIcon icon={transIcons[name]} {...{size, className, style}}/>

export const SocialMedia = ({
  name,
  size='6x',
  className,
  style
}) => <FontAwesomeIcon icon={transSocialMedia[name] || transSocialMedia.default} {...{size, className, style}}/>

export const AllIcons = ({ style, size }) => (
  <ul style={style.ul}>
    {Object.entries(transIcons).map((ico, i) => (<li style={style.li} key={i}>
      <FontAwesomeIcon icon={ico[1]} size={size} style={style.icon}/>
      <span style={style.span}>{ico[0]}</span>
    </li>))}
  </ul>
)